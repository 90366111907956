<template>
  <v-row>
    <v-col cols="12">
      <h3 class="mb-4">幻水総選挙 2020 まとめ・タグなどのご案内</h3>

      <h4>
        幻水総選挙 2020 開票ツイート まとめ（<a
          href="https://min.togetter.com/xabpoLv"
          target="_blank"
          >リンク先</a
        >）
      </h4>
      <p class="mb-4">開票日にツイートされた開票ツイートのまとめです。</p>

      <h4>
        幻水総選挙 2020 アンケート（<a
          href="https://ws.formzu.net/dist/S98097443/"
          target="_blank"
          >リンク先</a
        >）
      </h4>
      <p class="mb-4">感想などがありましたらどうぞ。</p>

      <h4>
        「#幻水総選挙2020後夜祭」（<a
          href="https://twitter.com/search?q=%23%E5%B9%BB%E6%B0%B4%E7%B7%8F%E9%81%B8%E6%8C%992020%E5%BE%8C%E5%A4%9C%E7%A5%AD&f=live"
          target="_blank"
          >リンク先</a
        >）
      </h4>
      <p class="mb-4">総選挙の感想やお祝いなどにご利用下さい。</p>

      <h4>
        「#幻水総選挙開票中」（<a
          href="https://twitter.com/search?q=%23%E5%B9%BB%E6%B0%B4%E7%B7%8F%E9%81%B8%E6%8C%99%E9%96%8B%E7%A5%A8%E4%B8%AD&f=live"
          target="_blank"
          >リンク先</a
        >）
      </h4>
      <p class="mb-4">開票を見守る、盛り上がるためのハッシュタグです。</p>

      <h4>
        「#幻水ネットプリント」（<a
          href="https://twitter.com/search?q=%23%E5%B9%BB%E6%B0%B4%E3%83%8D%E3%83%83%E3%83%88%E3%83%97%E3%83%AA%E3%83%B3%E3%83%88&src=typed_query&f=live"
          target="_blank"
          >リンク先</a
        >）
      </h4>
      <p class="mb-4">イラストのネットプリント登録に便利です。</p>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>

<style scoped></style>
